'use strict';

var base = require('../product/base');
var tooltip = require('../components/tooltip');
var customProductImages = require('../components/customProductImages');
var selectBox = require('../components/selectbox');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            if (data.valid.showError) {
                var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                    'fade show" role="alert">' +
                    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                    '<span aria-hidden="true">&times;</span>' +
                    '</button>' + data.valid.message + '</div>';

                $('.cart-error').append(errorHtml);
            }
        } else {
            $('.cart').empty();
            // Changes for Spark - BEGIN
            $('.cart-empty').removeClass('d-none');
            // Changes for Spark - END
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * updates the afterPay totals summary
 * @param {Array} grandTotals - the totals data
 */
$('body').on('cart:updateAfterPayInstallment', function (event, grandTotals) {
    var afterpayMessageHolder = $('.afterpay-message-holder > .afterpay-message > b');
    if (afterpayMessageHolder.length > 0 && grandTotals) {
        var totalvalue = parseFloat(grandTotals.replace(/[^\d.]/g, ''));
        var currencyAndValue = grandTotals.split(' ');
        var currency = currencyAndValue[0];
        var currencySymbol = currencyAndValue[1].charAt(0);
        var total = Math.ceil((totalvalue / 4) * 100) / 100;
        afterpayMessageHolder.html(currency + ' ' + currencySymbol + total);
      }
});

/**
 * re-renders the free shipping message
 * @param {Object} data - AJAX response from the server
 */
function showFreeShippingMsg(data) {
    if (data.cartFreeShippingMsg) {
        $('#free-shipping-container').removeClass('d-none');
        if (data.cartFreeShipping) {
            $('#free-shipping-msg').removeClass('free-shipping-threshold').addClass('free-shipping');
        } else {
            $('#free-shipping-msg').removeClass('free-shipping').addClass('free-shipping-threshold');
        }
        var msg = data.cartFreeShippingMsg.replace(data.thresholdAmount, '<strong>' + data.thresholdAmount + '</strong>');
        $('#free-shipping-msg').html(msg.toUpperCase());
    } else {
        $('#free-shipping-container').addClass('d-none');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotalWithoutDiscount);
    $('.minicart-quantity').empty().append(data.numItems);
    $('body').trigger('cart:updateAfterPayInstallment', data.totals.grandTotal);
    var cartTotalsNonMemberPromotionDiscount = $('.cart-totals-promotion-discount');
    cartTotalsNonMemberPromotionDiscount.addClass('hide-promotion-discount');
    var cartTotalsMemberPromotionDiscount = $('.cart-totals-member-discount');
    cartTotalsMemberPromotionDiscount.addClass('hide-promotion-discount');
    if (data.totals.promotionDiscount && data.totals.promotionDiscount.totalNonMemberPromoDiscountValue !== 0) {
        cartTotalsNonMemberPromotionDiscount.removeClass('hide-promotion-discount');
        $('.promotion-discount-total').empty().append('- ' + data.totals.promotionDiscount.totalNonMemberPromoDiscountFormattedMoney);
    }
    if (data.totals.promotionDiscount && data.totals.promotionDiscount.totalMemberPromoDiscountValue !== 0) {
        cartTotalsMemberPromotionDiscount.removeClass('hide-promotion-discount');
        $('.promotion-member-discount-total').empty().append('- ' + data.totals.promotionDiscount.totalMemberPromoDiscountFormattedMoney);
    }

    var incGstLabel = $('.inc-exc-tax').attr('data-inc-gst-label');
    var excGstLabel = $('.inc-exc-tax').attr('data-exc-gst-label');
    var totalLabel = $('.inc-exc-tax').attr('data-total-label');
    var shippingCountry = data.shippingCountry;
    var defaultCurrency = data.defaultCurrency;
    if (incGstLabel && excGstLabel && defaultCurrency) {
        var orderTotalText;
        if (data.totals.isGCOnlyOrder) {
            orderTotalText = totalLabel;
        } else if (defaultCurrency !== 'USD' && (shippingCountry === 'AU' || shippingCountry === '')) {
            orderTotalText = incGstLabel;
        } else if (defaultCurrency === 'USD') {
            orderTotalText = totalLabel;
        } else {
            orderTotalText = excGstLabel;
        }
        $('.order-receipt-label').text(orderTotalText);
    }

    var discountsArr;
    var orderDiscountCallout;
    var shippingDiscountCallout;

    if ('discounts' in data.totals && data.totals.discounts && data.totals.discounts.length > 0) {
        discountsArr = data.totals.discounts;
        for (var i = 0; i < discountsArr.length; i++) {
            // updating available order and shipping level promotion callouts while applying coupon code
            // to solve the issue with disappearance of order & shipping promo callouts in the cart summary while applying the coupon code
            if (discountsArr[i].type === 'coupon') {
                if ('promotions' in discountsArr[i] && discountsArr[i].promotions && discountsArr[i].promotions.length > 0) {
                    var promotionsArr = discountsArr[i].promotions;
                    for (let j = 0; j < promotionsArr.length; j++) {
                        if (promotionsArr[j].promoClass === 'ORDER') {
                            orderDiscountCallout = promotionsArr[j].promoCallout;
                        } else if (promotionsArr[j].promoClass === 'SHIPPING') {
                            shippingDiscountCallout = promotionsArr[j].promoCallout;
                        }
                    }
                }
            } else if (discountsArr[i].promoClass === 'ORDER') {
                orderDiscountCallout = discountsArr[i].promoCallout;
            } else if (discountsArr[i].promoClass === 'SHIPPING') {
                shippingDiscountCallout = discountsArr[i].promoCallout;
            }
        }
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-promo-callout').empty().append(orderDiscountCallout);
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
        $('.order-promo-callout').empty();
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
        $('.shipping-promo-callout').empty().append(shippingDiscountCallout);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
        $('.shipping-promo-callout').empty();
    }

    data.items.forEach(function (item) {
        // updating the product line item promotions while appliying coupon code
        // to make the available product level promo callout msgs appear while applying the coupon code
        var lineItemContainer = $('.card.product-info.uuid-' + item.UUID);
        var itemAttrCntr = lineItemContainer.find('.item-attributes');
        var sizeAttrTag = itemAttrCntr.find('.line-item-attributes.Size-' + item.UUID);
        var promotTags = [];
        itemAttrCntr.find('.line-item-attributes.promoView').remove();

        if ('appliedPromotions' in item && item.appliedPromotions && item.appliedPromotions.length > 0) {
            var promoArr = item.appliedPromotions;
            for (let j = 0; j < promoArr.length; j++) {
                var promoTag = '<p class="line-item-attributes promoView ' + promoArr[j].outletPromoClass + ' ' + item.salesClass + '">' + promoArr[j].callOutMsg + '</p>';
                promotTags.push(promoTag);
            }
            sizeAttrTag.after(promotTags);
        } else {
            itemAttrCntr.find('.line-item-attributes.promoView').remove();
        }

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        if (item.price && item.price.list && item.price.list.formatted) {
            $('.strike-price-each-' + item.UUID + '>span').html(item.price.list.formatted).attr('content', item.price.list.formatted);
        }
        if (item.price && item.price.sales && item.price.sales.formatted) {
            $('.sale-price-each-' + item.UUID + '>span').html(item.price.sales.formatted).attr('content', item.price.sales.decimalPrice);
        }
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);

        if (item.salesClass === 'forced-sale') {
                var priceContainer = $('.item-total-' + item.UUID).find('.pricing');
                priceContainer.addClass('forced-sale');
                priceContainer.css('color', '#f00');
        }
        if (item.promotionDiscount.nonMemberPromoDiscountValue !== 0) {
            $('.item-promo-total-each-' + item.UUID + '.non-member-discount-price').removeClass('hide-promotion-discount');
            $('.item-promo-total-each-' + item.UUID + '>.promos-amount.non-member-promo-amount').html('- ' + item.promotionDiscount.nonMemberPromoDiscountFormattedMoney);
        }
        if (item.promotionDiscount.memberPromoDiscountValue !== 0) {
            $('.item-promo-total-each-' + item.UUID + '.member-discount-price').removeClass('hide-promotion-discount');
            $('.item-promo-total-each-' + item.UUID + '>.promos-amount.member-promo-amount').html('- ' + item.promotionDiscount.memberPromoDiscountFormattedMoney);
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).html('');
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
    // update selectbox
    var $selectboxElements = $('.card.product-info.uuid-' + uuid + ' select.custom-select');
    selectBox.reInit($selectboxElements);
}

/**
 * New function in Spark that has replaced fillModalElement and getModalHTMElement functions from base
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function renderModal(editProductUrl) {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }

    if ($('.minicart .popover.show').length !== 0) {
        $('.minicart .popover.show').removeClass('show').empty();
    }

    $.spinner().start();

    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            $('body').append(data.renderedTemplate);
            var $container = $('#editProductModal');
            $container.find('.full-pdp-link').attr('href', data.productUrl);
            $container.modal('show');

            $('body').on('click', '.pdp-carousel .carousel-item a', function (e) {
                e.preventDefault();
            });

            $.spinner().stop();
            tooltip.tooltipPromotion();
            customProductImages.handleProductMissingImages('custom-zoom-image');
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName.toLowerCase());
}

// eslint-disable-next-line require-jsdoc
function hideExpressCheckout() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) $('.express-checkout-with').hide();
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');

        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0 && data.basket.giftCertCount === 0) {
                    $('.cart').empty();
                    $('.cart-empty').removeClass('d-none');

                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    showFreeShippingMsg(data);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }

                    $('.uuid-' + uuid).remove();

                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }

                    var hideShippingDetailsBlock = (data.basket.items.length === 0 && data.basket.giftCertCount > 0);
                    $('.js-cart-summary-shipping-section').toggle(hideShippingDetailsBlock);

                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);

                    updateCartTotals(data.basket);
                    showFreeShippingMsg(data);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);

                    $('body').trigger('setShippingMethodSelection', data.basket);

                    validateBasket(data.basket);
                }

                data.target = e.target;

                $('body').trigger('removeModal:removeItem', data);
                // Spark changes for GTM - GA4: BEGIN
                $('body').trigger('product:afterCartQuantityChanges', data);
                // Spark changes for GTM - GA4: END

                if (data && data.hideApplePay) {
                    $('.express-checkout-with').hide();
                    $('.apple-pay-button').hide();
                } else if (!data.hideApplePay) {
                    $('.express-checkout-with').show();
                    $('.apple-pay-button').show();
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function (e) {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var targetElem = e.target;
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                showFreeShippingMsg(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);
                data.target = targetElem;
                $('body').trigger('product:afterAddToCart', data);
                // Spark changes for GTM - GA4: BEGIN
                $('body').trigger('product:afterCartQuantityChanges', data);
                if (data.stockErrorMessage !== 'Out of Stock') {
                    $(this).parents('.product-info').removeClass('outofstock-product');
                    $(this).parents('.product-info').removeClass('partial-outofstock-product');
                }
                if (data.errorMessageToAppend && data.stockErrorMessage) {
                    $(this).parents('.product-info').find('.line-item-insufficient-stock').text(data.errorMessageToAppend);
                    $(this).parents('.product-info').find('.line-item-availability').append('<div class="line-item-attributes">' + data.stockErrorMessage + '</div>');
                } else {
                    $(this).parents('.product-info').find('.line-item-insufficient-stock').text('');
                    $(this).parents('.product-info').find('.line-item-availability ').text('');
                }
                if ((data.isInStock || data.isOutOfStock) && data.stockErrorMessage) {
                    $(this).parents('.product-info').find('.line-item-availability').append('<div class="line-item-attributes">' + data.stockErrorMessage + '</div>');
                }
                // Spark changes for GTM - GA4: END
                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('.coupon-code-field').focus();
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    showFreeShippingMsg(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                showFreeShippingMsg(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button, .cart-page .bonus-product-edit-link', function () {
        if ($('#chooseBonusProductModal').length !== 0) {
            $('#chooseBonusProductModal').remove();
        }
        // Remove  Bonus Product Modal if Exist : END
    
        $.spinner().start();
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
    $('body').on('click', '.product-edit .edit, .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        // Changes for Spark - BEGIN
        renderModal(editProductUrl, e.target);
        // Changes for Spark - END
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').remove();
                    $('.modal-backdrop').remove();
                    $('body').removeClass('modal-open');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);
                    $('body').trigger('cart:update', data);

                    // Changes for Spark - BEGIN
                    location.reload();
                    // Changes for Spark - END

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        response.container.find('.promotion-slick-slider .promoView').addClass(response.data.product.salesClass);
        response.container.find('.promotion-slick-slider .promoView .i-tooltip').attr('class', 'i-tooltip icon icon-' + response.data.product.salesClass + '-info');
        customProductImages.handleProductMissingImages('custom-zoom-image');
    });

    base.selectAttribute();
    base.selectAttributeSize();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();

    hideExpressCheckout();
};
